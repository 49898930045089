export default {
  port: 3000,
  appName: "opera",
  envUrl: "https://opera.sumit-ai.com",
  server: {
    host: "", // Server's host
    auth: "auth",
    mfa: "auth/mfa",
    metadata: "metadata",
    users: "users",
    jobs: "jobs",
    attachments: "attachments",
    uploads: "uploads",
    tags: "tags",
    files: "files",
    export: "export",
    search: "search",
    clients: "clients",
    docxTemplates: "docxTemplates",
    assignments: "assignments",
    validationPresets: "validationPresets",
    communityPayments: "communityPayments",
    userInvitations: "userInvitations",
    invoices: "invoices",
    media: "media",
    ooona: "ooona",
    projects: "projects",
  },
  googleAuthProvider: {
    scopes: {
      calendar: [
        "https://www.googleapis.com/auth/calendar.readonly",
        "https://www.googleapis.com/auth/userinfo.profile",
        "https://www.googleapis.com/auth/userinfo.email",
      ],
    },
    apiKey: "",
    clientId: "",
  },
  firebase: {}, // Firebase config
  featureFlags: {
    host: "https://eu.app.unleash-hosted.com/euee0010/api/frontend",
    apiKey:
      "opera:production.96df3bc685908d45f0af9612ea576fc1e100f1791163e43b8102052a",
    appName: "opera",
  },
  cloudinary: {},
  delivery: {
    minDeliveryDays: 4,
    minAutoTranscriptDeliveryHours: 12,
  },
  sentry: {
    dsn: "https://995c521ad7992d505f061fb2f41100d6@o689365.ingest.sentry.io/4506428465872896",
  },
  versionHistory: "https://app.clickup.com/5709552/v/dc/5e7qg-13875",
};
